import {POST_TARIF, POST_TARIF_RELOAD, setTarif, TARIF, TARIF_RELOAD} from '../../../actions/app/tarif/tarif.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {
    setError,
    setErrorTarif,
    setLoader,
} from '../../../actions/app/ui/ui.actions'
import {formatDataANForApi} from "@amo/core/utils/formatDataANForApi.js";

export const tarifMiddleware = () => next => action => {
    next(action)

    const { payload } = action
    let nextActions = []
    let body = {}

    switch (action.type){

        case POST_TARIF:


            if (payload.body){
                body = formatDataANForApi(payload.body)
            }

            next([
                apiRequest({body: body, method: 'POST', url: payload.data, entity: TARIF}),
                setLoader({state: true, entity: TARIF}),
                setErrorTarif({data: ''})
            ]);
            break;

        case `${TARIF} ${API_SUCCESS}`:
            nextActions = [
                setTarif({data: payload.data.data.Tarif}),
                setError({state: false, entity: TARIF}),
                setLoader({state: false, entity: TARIF})
            ]

            next(nextActions)
            break;

        case `${TARIF} ${API_ERROR}`:
            next([
                setErrorTarif({data: payload.data.response.data.error}),
                setError({state: true, entity: TARIF}),
                setLoader({state: false, entity: TARIF})
            ]);
            break;

        case POST_TARIF_RELOAD:

            if (payload.body){
                body = formatDataANForApi(payload.body)
            }

            next([
                apiRequest({body: body, method: 'POST', url: payload.data, entity: TARIF_RELOAD}),
                setLoader({state: true, entity: TARIF})
            ]);
            break;

        case `${TARIF_RELOAD} ${API_SUCCESS}`:
            next([
                setTarif({data: payload.data.data.Tarif}),
                setError({state: false, entity: TARIF}),
                setLoader({state: false, entity: TARIF})
            ]);
            break;

        case `${TARIF_RELOAD} ${API_ERROR}`:
            next([
                setErrorTarif({data: payload.data.response.data.error}),
                setError({state: true, entity: TARIF}),
                setLoader({state: false, entity: TARIF})
            ]);
            break;

        default:
            return null
    }
}
