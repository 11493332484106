export const DEJA_CLIENT = '[Deja Client]'

export const POST_DEJA_CLIENT = `${DEJA_CLIENT} Post`
export const REFRESH_DEJA_CLIENT = `${DEJA_CLIENT} Refresh`
export const SET_DEJA_CLIENT = `${DEJA_CLIENT} Set`

export const postDejaClient = ({idDejaClient, query, otherData}) => ({
    type: POST_DEJA_CLIENT,
    payload: {
        data: query,
        body: {idDejaClient},
        meta : {otherData}
    }
})

export const dejaClientRefresh = ({data,dejaClientData}) => ({
    type: REFRESH_DEJA_CLIENT,
    payload: {
        data,
        dejaClientData: dejaClientData
    }
})

export const setDejaClient = ({data}) => ({
    type: SET_DEJA_CLIENT,
    payload: {
        data
    }
})