import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {POST_DEMANDE_RAPPEL} from "../../../actions/app/demandeRappel/demandeRappel.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import { formatDataForApi } from "./formatDataForApi";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {reset} from "redux-form";
import {setTrackAction} from "../../../actions/core/analytics/analytics.actions";

export const demandeRappelMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_DEMANDE_RAPPEL:
            const body = formatDataForApi(payload.body)

            next([
                apiRequest({body: body, method: 'POST', url: payload.data, entity: POST_DEMANDE_RAPPEL}),
                setLoader({state: true, entity: POST_DEMANDE_RAPPEL})
            ])

            break

        case `${POST_DEMANDE_RAPPEL} ${API_SUCCESS}`:
            next([
                setNotification({entity: POST_DEMANDE_RAPPEL, body: 'Un gestionnaire dédié vous recontactera dans les délais demandés.', type: 'success', title: 'Merci, votre demande a bien été prise en compte.'}),
                setTrackAction({event: 'click', actionGoogle: 'clickButton', libelle: 'Demande de rappel', categorie: 'WEBCALLBACK', titleMatomo: 'clickButton', valueMatomo: 'Demande de rappel'}),
                setError({state: false, entity: POST_DEMANDE_RAPPEL}),
                setLoader({state: false, entity: POST_DEMANDE_RAPPEL}),
                reset('demande-rappel')
            ])
            break

        case `${POST_DEMANDE_RAPPEL} ${API_ERROR}`:
            next([
                setNotification({entity: POST_DEMANDE_RAPPEL, body: `<p>Une erreur est survenue.</p>`, type: 'error', title: 'Erreur !', timeout: '0'}),
                setError({state: true, entity: POST_DEMANDE_RAPPEL}),
                setLoader({state: false, entity: POST_DEMANDE_RAPPEL})
            ])
            break

        default:
            return null
    }
}
