import _ from 'lodash'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIndexQuestion, setLoader, setMaxStep, setStep} from '../../../actions/app/ui/ui.actions'
import {POST_FILL, FILL} from "../../../actions/app/fill/fill.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {postReloadTarif, TARIF} from "../../../actions/app/tarif/tarif.actions";
import {getSlugStep} from "../../../../utils/function";
import {formatDataANForApi} from "@amo/core/utils/formatDataANForApi.js";

export const fillMiddleware = ({dispatch}) => next => action => {
    next(action)

    const { payload } = action
    let nextActions = []

    switch (action.type){

        case POST_FILL:
            const body = formatDataANForApi(payload.body)

            next([
                apiRequest({body, method: 'POST', url: payload.data, entity: FILL, otherData: payload.meta.otherData}),
                setLoader({state: true, entity: FILL})
            ])

            break

        case `${FILL} ${API_SUCCESS}`:
            const {step, maxStep, urlReloadTarif, form} = payload.meta.otherData

            /* Analytics */
            const index = _.findIndex(window.dataLayer, ['key', 'formulaire'])
            window.dataLayer[index] = {
                key: 'formulaire',
                values: action.payload.data
            }

            /* Matomo */
            window._paq.push([
                'trackEvent',
                'infos',
                'formulaire',
                action.payload.data
            ])

            window.scroll(0,0)

            nextActions = [
                setStep({step}),
                setIndexQuestion({index: 0}),
                setError({state: false, entity: FILL}),
                setLoader({state: false, entity: FILL})
            ]

            if (step > maxStep){
                nextActions.push(setMaxStep({maxStep: step}))
            }

            if (step === getSlugStep('votre-tarif')) {
                // On bloque le state du tarif pour ne pas charger la page trop tôt si on fait retours/suivant
                nextActions.unshift(setLoader({state: true, entity: TARIF}))
                dispatch(postReloadTarif({form, query: urlReloadTarif}))
            }

            next(nextActions)
            break

        case `${FILL} ${API_ERROR}`:
            next([
                setNotification({entity: FILL, body: action.payload.data.response.data.error, title: 'Attention !', type: 'error'}),
                setError({state: true, entity: FILL}),
                setLoader({state: false, entity: FILL})
            ])
            break

        default:
            return null
    }
}
