import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Vehicule from "./Vehicule";
import formValueSelector from "redux-form/lib/formValueSelector";
import {change, getFormValues} from "redux-form";
import {getApporteur1Init, getToken} from "../../../../../../redux/selectors/init/init.selectors";
import {
    dejaClientRefresh,
    POST_DEJA_CLIENT,
    postDejaClient
} from "../../../../../../redux/actions/app/dejaClient/dejaClient.actions";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getSouscriptionDisabledVehicule} from "../../../../../../redux/selectors/vehicule/vehicule.selectors";
import { API_URL_WS_FORMULAIRE } from '@amo/core/constants';
import {getDejaClient} from "../../../../../../redux/selectors/dejaClient/dejaClient.selectors.js";
import _ from "lodash";
import {setVehicule} from "../../../../../../redux/actions/app/vehicule/vehicule.actions.js";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    return {
        marque: selector(state, `marqueVehicule`),
        cylindree: selector(state, `cylindreeVehicule`),
        modele: selector(state, `modeleVehicule`),
        familleProduit: selector(state, `typeVehicule`),
        connu: selector(state, `DemandeDevis[ConnuComment]`),
        apporteur1: getApporteur1Init(state),
        idDejaClient: selector(state, 'DemandeTarif[ListePersonnes][0][NumeroClient]'),
        idSRA: selector(state, 'DemandeTarif[Vehicule][IdentifiantSRA]'),
        loadingDejaClient: getLoadingEntity(state, POST_DEJA_CLIENT),
        vehiculeIsDisabled: getSouscriptionDisabledVehicule(state),
        queryDejaClient: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        dejaClientValide: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true',
        dejaClientNom: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true' ? (selector(state, 'DemandeTarif[ListePersonnes][0][Nom]') ? selector(state, 'DemandeTarif[ListePersonnes][0][Nom]') : selector(state, 'DemandeTarif[ListePersonnes][1][Nom]')) : null,
        dejaClientPrenom: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true' ? (selector(state, 'DemandeTarif[ListePersonnes][0][Prenom]') ? selector(state, 'DemandeTarif[ListePersonnes][0][Prenom]') : selector(state, 'DemandeTarif[ListePersonnes][1][Prenom]')) : null,
        dejaClientBirthdate: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true' ? (selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]') ? selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]') : selector(state, 'DemandeTarif[ListePersonnes][1][DateNaissance]')) : null,
        listePersonnes: selector(state, 'DemandeTarif[ListePersonnes]'),
        dejaClientData: getDejaClient(state),
        searchImmat: selector(state, `searchImmat`),
        searchBy: selector(state, `searchBy`),
        immatLog: selector(state, 'immat_log')
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value),
    postDejaClient,
    dejaClientRefresh,
    setVehicule: data => setVehicule({data})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { idDejaClient, queryDejaClient, familleProduit, listePersonnes, dejaClientData } = stateProps
    const { postDejaClient, dejaClientRefresh } = dispatchProps
    const newDatas = {
        typeProduit: familleProduit,
        listePersonnes: listePersonnes
    };

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postDejaClient: () => postDejaClient({idDejaClient, query: queryDejaClient, otherData: newDatas}),
        dejaClientRefresh: (dataOverride) => !_.isEmpty(dejaClientData) ? dejaClientRefresh({data: {...newDatas, ...dataOverride}, dejaClientData: dejaClientData}) : {},
    }
}

const VehiculeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Vehicule)

export default VehiculeContainer
